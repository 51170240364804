export const Home_EN_Trans = {
  Headline: "Fiesta Catering",
  event: "Event & Party",
  SubHeadline: "Book Your Caterer",
  note: `You decide! Do you want to search for and book your catering service yourself, or do you need assistance? With us, you have the choice.`,
  button: "Submit Inquiry?",
  inquiry: {
    title: "Inquiry Form",
    submit: "Submit Inquiry",
  },
};

export const Home_DE_Trans = {
  Headline: "Fiesta Catering",
  event: "Event & Party",
  SubHeadline: "Buche deinen Caterer",
  note: `Du entscheidest! Willst du dein Cateringservice selbst suchen und buchen oder benötigst du Unterstützung? Bei uns hast du die Wahl.`,
  button: "Anfrage stellen?",
  inquiry: {
    title: "Anfrageformular",
    submit: "Anfrage stellen",
  },
};

// export const Home_ES_Trans = {
//   Headline: "Servicio de Catering de Food Trucks",
//   // Headline2: "Evento",
//   SubHeadline: "ALQUILA TU FOOD TRUCK",
//   button: "¿Hacer una solicitud?",
// };

export const Home_ES_Trans = {
  Headline: "Catering Fiesta",
  event: "Evento ",
  party: " Fiesta",
  SubHeadline: "Reserva tu Caterer",
  note: `¡Tú decides! ¿Quieres buscar y reservar tu servicio de catering tú mismo o necesitas asistencia? Con nosotros, tienes la opción.`,
  button: "¿Enviar una consulta?",
  inquiry: {
    title: "Formulario de Consulta",
    submit: "Enviar Consulta",
  },
};
