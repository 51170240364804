export const Explore_En_trans = {
  title: "Discover Caterer",
  subtitle: `Search for a suitable caterer for your event and browse through our selection of certified partners.`,
  edit: "Edit",
  more: "Show Me More",
  button: "Book Now",
  moreDetails:"More Details",
  foodtruckdetails: "Caterer Details",
  foodtruck: "Caterer",
  fiestaCatering: "Fiesta Catering",
  home: "Home",
  foodcategories: "Food Categories",
  certificate: "Certificate",
  clientreviews: "Client Reviews",
  address: "Address",
  postalcode: "Postal Code",
  water:"Water & Electricity available",
  meal:"Meals",
  size: "Size in meters",
  power: "Power",
  sendmessage: "Send a message",
  myreviews: "My Reviews",
  leaveyourreviews: "Leave your review",
  reviewPlc: "Write your review here",
  equipment: "Equipment required on site",
  submit: "Submit",
};

export const Explore_NL_Trans = {
  title: "Ontdek de Foodtruck",
  subtitle:
    "Het is een bekend gegeven dat een lezer wordt afgeleid door de leesbare inhoud.",
  button: "Boek Nu",
  edit: "Bewerken",
  foodtruckdetails: "Details van de foodtruck",
  fiestaCatering: "Feest Catering",
  foodtruck: "Caterer",
  home: "Startseite",
  foodcategories: "Voedselcategorieën",
  certificate: "certificaat",
  clientreviews: "Klantrecensies",
  address: "Adres",
  postalcode: "postcode",
  size: "Maat",
  power: "stroom",
  sendmessage: "Stuur een bericht",
  myreviews: "mijn beoordelingen",
  leaveyourreviews: "laat uw beoordelingen achter",
};

export const Explore_DE_Trans = {
  title: "Caterer entdecken",
  subtitle: `Suche selbst nach einem passenden Caterer für dein Event und klicke dich durch unser Angebot an zertifizierten Partnern.`,
  edit: "Bearbeiten",
  more: "Zeig Mir Mehr",
  button: "Jetzt buchen",
  moreDetails: "Mehr Details",
  equipment: "Erforderliche Ausrüstung vor Ort",
  foodtruckdetails: "Details zum Caterer",
  foodtruck: "Caterer",
  fiestaCatering: "Fiesta Catering",
  home: "Startseite",
  water: "Wasser und Strom verfügbar",
  meal: "Verpflegung",
  foodcategories: "Essens Kategorien",
  certificate: "Zertifikat",
  clientreviews: "Kundenbewertungen",
  address: "Adresse",
  postalcode: "Postleitzahl",
  size: "Größe in Metern",
  power: "Leistung",
  sendmessage: "Nachricht senden",
  myreviews: "Meine Bewertungen",
  leaveyourreviews: "Hinterlassen Sie Ihre Bewertung",
  reviewPlc: "Schreiben Sie hier Ihre Bewertung",
  submit: "Einreichen",
};

export const Explore_ES_trans = {
  title: "Explora el Food Truck",
  subtitle:
    "Es un hecho bien establecido que un lector se distraerá con lo legible",
  button: "Reservar ahora",
  edit: "Editar",
  foodtruckdetails: "Detalles del Food Truck",
  foodtruck: "Food Truck",
  fiestaCatering: "Fiesta Catering",
  home: "Inicio",
  foodcategories: "Categorías de Comida",
  certificate: "Certificado",
  clientreviews: "Reseñas de Clientes",
  address: "Dirección",
  postalcode: "Código Postal",
  size: "Tamaño",
  power: "Potencia",
  sendmessage: "Enviar un mensaje",
  myreviews: "Mis Reseñas",
  leaveyourreviews: "Deja tu reseña",
};
