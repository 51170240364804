export const MyBooking_EN_trans = {
  title: "My Booking",
  all: "All",
  pending: "Pending",
  cancelled: "Cancelled",
  booknow: "Book Now",
  Electricity: "Electricity",
  Meters: "Meter",
  startDate:"Start Date",
  endDate:"End Date",
  cName:"Caterer Name",
  noData:"No Record found"
};
export const MyBooking_DE_trans = {
  title: "Meine Buchung",
  all: "Alle",
  pending: "Ausstehend",
  cancelled: "Storniert",
  booknow: "Jetzt buchen",
  Electricity: "Elektrizität",
  Meters: "Meter",
  startDate: "Startdatum",
  endDate: "Enddatum",
  cName: "Caterername",
  noData: "Kein Datensatz gefunden"
};


export const MyBooking_NL_trans = {
  title: "Mijn Boekingen",
  all: "Alle",
  pending: "In Behandeling",
  cancelled: "Geannuleerd",
  booknow: "Boek Nu",
  Electricity: "Elektriciteit",
  Meters: "Meter",
};


export const MyBooking_ES_trans = {
  title: "Mi Reserva",
  all: "Todos",
  pending: "Pendiente",
  cancelled: "Cancelado",
  booknow: "Reservar ahora",
  Electricity: "Electricidad",
  Meters: "Metros",
};
