import React, { createContext, useContext, useState, useEffect } from "react";
import { API_GET_USERID } from "../API/config";
import { API_endpoints } from "../API/API_Endpoints";

const CompanyIdContext = createContext();

export const CompanyIdProvider = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("Fiesta"));
  const [companyId, setCompanyId] = useState(null);
  const [renderTrigger, setRenderTrigger] = useState(false);

  const getCompanyId = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, userData?.id);
      if (res.s)
      {
        const id = res?.r?.company_details?.id;
        setCompanyId(id);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const toggleRender = () => setRenderTrigger((prev) => !prev);

  useEffect(() => {
    getCompanyId();
  }, [renderTrigger]);

  return (
    <CompanyIdContext.Provider
      value={{ companyId, toggleRender, renderTrigger, setCompanyId }}
    >
      {children}
    </CompanyIdContext.Provider>
  );
};

export const useCompanyId = () => {
  return useContext(CompanyIdContext);
};
