export const TandC_EN_Trans = {
    title: "Terms & Conditions"
};

export const TandC_DE_Trans = {
    title: "Allgemeine Geschäftsbedingungen"
};



export const TandC_NL_Trans = {
    title: "Algemene Voorwaarden"
};

export const TandC_ES_Trans = {
    title: "Términos y Condiciones"
};

