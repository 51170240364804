export const Privacy_EN_Trans={
    title:'Privacy Policy'
}

export const Privacy_DE_Trans = {
    title: 'Datenschutzrichtlinie'
};


export const Privacy_NL_Trans = {
    title: "Privacybeleid"
};

export const Privacy_ES_Trans = {
    title: 'Política de Privacidad'
};

