export const ConfirmationModal_EN_trans = {
  note: "Are you sure you want to",
  cancel: "Cancel",
  confirm: "Confirm",
};

export const ConfirmationModal_DE_trans = {
  note: "Möchten Sie sich wirklich ",
  cancel: "Abbrechen",
  confirm: "Bestätigen",
};
