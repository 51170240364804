export const AboutUS_EN_trans = {
  aboutus: "About Us",
  headline:
    "Fiesta Catering – We are the event professionals with taste! Our heart beats for unique experiences and delicious moments that simply taste like “more”. Whether it's crispy tacos, creamy ice cream or freshly brewed coffee - we ensure that every event becomes a culinary adventure! As an experienced agency, we know the best food trucks and caterers to take celebrations to a new level, from casual company events to the dream wedding. Our motto: The more colorful, the better, the tastier, the more fiesta! Let's make your event a celebration of the year together - with heart, humor and lots of taste!",
  subline1: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.The point of using Lorem Ipsum`,
  subline2: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.The point of using Lorem Ipsum`,
  mediaTitle:
    "The best food trucks and caterers for your event. Easy planning, diverse enjoyment, unforgettable moments!",
  count: {
    title: "Our Fiesta in Numbers",
    note: "The milestones and tasteful adventures at a glance!",
    card1: "Booked events",
    card2: "Registered caterers",
    card3: "Food sold",
  },
  menu: {
    title: "Explore the Food Truck",
  },
};

export const AboutUS_DE_trans = {
  aboutus: "Über uns",
  headline:
    "Fiesta Catering – Wir sind die Eventprofis mit Geschmack! Unser Herz schlägt für einzigartige Erlebnisse und köstliche Momente, die einfach nach „mehr“ schmecken.Ob es knusprige Tacos, cremiges Eis oder frisch gebrühter Kaffee ist – wir sorgen dafür, dass jedes Event zum Gaumenabenteuer wird! Als erfahrene Agentur kennen wir die besten Foodtrucks und Caterer, um Feiern auf ein neues Level zu heben, von lockeren Firmen- Events bis hin zur Traumhochzeit.Unser Motto: Je bunter, desto besser, je leckerer, desto Fiesta! Lassen Sie uns gemeinsam Ihre Veranstaltung zur Feier des Jahres machen – mit Herz, Humor und ganz viel Geschmack!",
  subline1: `Es ist eine allgemein bekannte Tatsache, dass ein Leser von den lesbaren Inhalten einer Seite abgelenkt wird, wenn er sich das Layout ansieht. Der Zweck der Verwendung von Lorem Ipsum`,
  subline2: `Es ist eine allgemein bekannte Tatsache, dass ein Leser von den lesbaren Inhalten einer Seite abgelenkt wird, wenn er sich das Layout ansieht. Der Zweck der Verwendung von Lorem Ipsum`,
  mediaTitle:
    "Die besten Foodtrucks und Caterer für Dein Event. Einfache Planung, vielfältiger Genuss, unvergessliche Momente!",
  count: {
    title: "Unsere Fiesta in Zahlen",
    note: "Die Meilensteine und geschmackvollen Abenteuer auf einen Blick!",
    card1: "Gebuchte Events",
    card2: "Registrierte Caterer",
    card3: "Verkaufte Speisen",
  },
  menu: {
    title: "Unsere Fiesta in Zahlen",
  },
};

export const AboutUS_NL_trans = {
  aboutus: "Over ons",
  headline:
    "Fiesta Catering – Wir sind die Eventprofis mit Geschmack! Unser Herz schlägt für einzigartige Erlebnisse und köstliche Momente, die einfach nach „mehr“ schmecken.Ob es knusprige Tacos, cremiges Eis oder frisch gebrühter Kaffee ist – wir sorgen dafür, dass jedes Event zum Gaumenabenteuer wird! Als erfahrene Agentur kennen wir die besten Foodtrucks und Caterer, um Feiern auf ein neues Level zu heben, von lockeren Firmen- Events bis hin zur Traumhochzeit.Unser Motto: Je bunter, desto besser, je leckerer, desto Fiesta! Lassen Sie uns gemeinsam Ihre Veranstaltung zur Feier des Jahres machen – mit Herz, Humor und ganz viel Geschmack!",
  subline1: `Het is een bekend feit dat een lezer afgeleid zal worden door de leesbare inhoud van een pagina bij het bekijken van de opmaak. Het punt van het gebruik van Lorem Ipsum`,
  subline2: `Het is een bekend feit dat een lezer afgeleid zal worden door de leesbare inhoud van een pagina bij het bekijken van de opmaak. Het punt van het gebruik van Lorem Ipsum`,
  mediaTitle: "Het is een bekend feit dat een lezer afgeleid zal worden.",
  count: {
    title: "Ontdek de Foodtruck",
    note: "Het is een bekend feit dat een lezer afgeleid zal worden door de leesbare inhoud",
    card1: "Besteld",
    card2: "Foodtrucks geregistreerd",
    card3: "Gerechten",
  },
  menu: {
    title: "Ontdek de Foodtruck",
  },
};

export const AboutUS_ES_trans = {
  aboutus: "Sobre Nosotros",
  headline: "Es un hecho bien conocido que un lector se distraerá",
  subline1: `Es un hecho bien conocido que un lector se distraerá con el contenido legible de una página al ver su diseño. El propósito de usar Lorem Ipsum`,
  subline2: `Es un hecho bien conocido que un lector se distraerá con el contenido legible de una página al ver su diseño. El propósito de usar Lorem Ipsum`,
  mediaTitle: "Es un hecho bien conocido que un lector se distraerá.",
  count: {
    title: "Explora el Camión de Comida",
    note: "Es un hecho bien conocido que un lector se distraerá con el contenido legible",
    card1: "Pedidos",
    card2: "Camiones de Comida Registrados",
    card3: "Platos de Comida",
  },
  menu: {
    title: "Explora el Camión de Comida",
  },
};
