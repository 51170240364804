export const OwnerProfile_EN_trans = {
  myProfile: "My Profile",
  title: "Partner Dashboard",
  dash: {
    title: "Dashboard",
    note: "Food Truck Booking Information",
    latest: "Latest Bookings",
    cards: {
      total: "Total",
      complete: "Approved",
      pending: "Pending",
      cancel: "Cancelled",
    },
    grid: {
      profile: "Profile",
      booking: "Booking Date",
      name: "Name",
      eDate: "Ending Date",
    },
  },
  profile: {
    title: "My Profile",
    note: "Profile Information",
    reset: "Reset Password",
    company: "Company Images",
    certi: "Essential Certificate",
    update: "Update",
  },
  company: {
    title: "My Company",
    note: "Company Information",
    update: "Update",
    tabs: {
      companydetails: "Company Details",
      caterertype: "Caterer Type",
      meal: "Food offering",
      images: "Images",
    },
  },
  payment: {
    title: "My Subscriptions",
    note: "Subscription Payment History",
    grid: {
      id: " Paypal Id",
      Image: "Image",
      status: "Status",
      subId: "Subscription Id",
      pID: "Plan ID",
      Date: "Payment Date",
      eDate: "Expiry Date",
      action: "Action",
    },
    cancelPlan: "Are you sure ?",
    cancelPlanText: "Are you sure want to cancel your subscription ?",
    cancel: "Cancel",
    cancelSub: "Cancel Subscription",
    Confirm: "Confirm",
    successTitle: "Success",
    successMessage: "Your plan has been successfully cancelled.",
    errorTitle: "Error",
    errorMessage: "There was an error cancelling your plan. Please try again.",
    verify: "Verify",
    renew: "Renew",
    renewMembership: 'Renew Your Membership',

  },
  review: {
    title: "Customer Reviews",
    note: "Reviews",
    grid: {
      Profile: "Profile",
      name: "Name",
      Review: "Review",
      diplay: "Display Review",
    },
  },
  booking: {
    title: "My Orders",
    note: "Current Orders",
    grid: {
      Profile: "Profile",
      name: "User Name",
      booking: "Order Date",
      eDate: "Ending Date",
      view: "View",
      action: "Action",
      ViewDetail: "View Details",
    },
    myBooking: {
      title: "My Order",
      city: "City Name",
      add: "Address",
      postal: "Postal Code",
      Type: "Type of Diets",
      meal: "Type of Meal",
      date: "Date",
      Contact: "Contact Details",
      person: "Persons",
      budget: "Budget per Person",
      amount: "Paid Amount",
    },
  },
  order: {
    title: "My Bookings",
    note: "Current Booking",
    grid: {
      Profile: "Profile",
      name: "User Name",
      booking: "Booking Date",
      eDate: "Ending Date",
      view: "View",
      action: "Action",
      ViewDetail: "View Details",
    },
    myBooking: {
      title: "My Booking",
      city: "City Name",
      add: "Address",
      postal: "Postal Code",
      Type: "Type of Diets",
      meal: "Type of Meal",
      date: "Date",
      Contact: "Contact Details",
      person: "Persons",
      budget: "Budget per Person",
      amount: "Paid Amount",
    },
  },
  caterer: {
    typeofcaterer: "Type of caterer",
    moreaboutcompany: "More about Company",
  },
  meal: {
    categoryclass: "Categorical classification of meals",
    updatediet: "Update your special diet?",
  },
};


export const OwnerProfile_DE_trans = {
  myProfile: "Mein Profil",
  title: "Partner-Dashboard",
  dash: {
    title: "Dashboard",
    note: "Informationen zur Buchung von Food Trucks",
    latest: "Neueste Buchungen",
    cards: {
      total: "Gesamt",
      complete: "Genehmigt",
      pending: "Ausstehend",
      cancel: "Storniert",
    },
    grid: {
      profile: "Profil",
      booking: "Buchungsdatum",
      name: "Name",
      eDate: "Enddatum",
    },
  },
  profile: {
    title: "Mein Profil",
    note: "Profilinformationen",
    reset: "Passwort zurücksetzen",
    company: "Unternehmensbilder",
    certi: "Wichtige Zertifikate",
    update: "Aktualisieren",
  },
  company: {
    title: "Mein Unternehmen",
    note: "Unternehmensinformationen",
    update: "Aktualisieren",
    tabs: {
      companydetails: "Unternehmensdetails",
      caterertype: "Caterer-Typ",
      meal: "Speiseangebot",
      images: "Bilder",
    },
  },
  payment: {
    title: "Meine Abonnements",
    note: "Zahlungshistorie des Abonnements",
    grid: {
      id: "Paypal-ID",
      Image: "Bild",
      status: "Status",
      subId: "Abonnement-ID",
      pID: "Plan-ID",
      Date: "Zahlungsdatum",
      eDate: "Ablaufdatum",
      action: "Aktion",
    },
    cancelPlan: "Sind Sie sicher?",
    cancelPlanText: "Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten?",
    cancel: "Abbrechen",
    cancelSub:'Abo kündigen',
    Confirm: "Bestätigen",
    successTitle: "Erfolg",
    successMessage: "Ihr Plan wurde erfolgreich gekündigt.",
    errorTitle: "Fehler",
    errorMessage: "Bei der Kündigung Ihres Plans ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    verify: "Überprüfen",
    renew: "Erneuern",
    renewMembership: 'Erneuern Sie Ihre Mitgliedschaft',
  },
  review: {
    title: "Kundenbewertungen",
    note: "Bewertungen",
    grid: {
      Profile: "Profil",
      name: "Name",
      Review: "Bewertung",
      diplay: "Bewertung anzeigen",
    },
  },
  booking: {
    title: "Meine Bestellungen",
    note: "Aktuelle Bestellungen",
    grid: {
      Profile: "Profil",
      name: "Benutzername",
      booking: "Bestelldatum",
      eDate: "Enddatum",
      view: "Ansehen",
      action: "Aktion",
      ViewDetail: "Details ansehen",
    },
    myBooking: {
      title: "Meine Bestellung",
      city: "Stadtname",
      add: "Adresse",
      postal: "Postleitzahl",
      Type: "Art der Diäten",
      meal: "Art der Mahlzeit",
      date: "Datum",
      Contact: "Kontaktdaten",
      person: "Personen",
      budget: "Budget pro Person",
      amount: "Bezahle Betrag",
    },
  },
  order: {
    title: "Meine Buchungen",
    note: "Aktuelle Buchung",
    grid: {
      Profile: "Profil",
      name: "Benutzername",
      booking: "Buchungsdatum",
      eDate: "Enddatum",
      view: "Ansehen",
      action: "Aktion",
      ViewDetail: "Details ansehen",
    },
    myBooking: {
      title: "Meine Buchung",
      city: "Stadtname",
      add: "Adresse",
      postal: "Postleitzahl",
      Type: "Art der Diäten",
      meal: "Art der Mahlzeit",
      date: "Datum",
      Contact: "Kontaktdaten",
      person: "Personen",
      budget: "Budget pro Person",
      amount: "Bezahle Betrag",
    },

  },
  caterer: {
    typeofcaterer: "Art des Caterers",
    moreaboutcompany: "Mehr über das Unternehmen",
  },
  meal: {
    categoryclass: "Kategorische Klassifizierung von Mahlzeiten",
    updatediet: "Ihre spezielle Diät aktualisieren?",
  },
};


export const OwnerProfile_NL_trans = {
  myProfile: "Mijn Profiel",
  title: "Partner Dashboard",
  dash: {
    title: "Dashboard",
    note: "Informatie over Food Truck Boekingen",
    latest: "Laatste Boekingen",
    cards: {
      total: "Totaal",
      complete: "Goedgekeurd",
      pending: "In Behandeling",
      cancel: "Geannuleerd",
    },
    grid: {
      profile: "Profiel",
      booking: "Boekingsdatum",
      name: "Naam",
      eDate: "Einddatum",
    },
  },
  profile: {
    title: "Mijn Profiel",
    note: "Profielinformatie",
    reset: "Wachtwoord Resetten",
    company: "Bedrijfsafbeeldingen",
    certi: "Essentieel Certificaat",
    update: "Bijwerken",
  },
  company: {
    title: "Mijn Bedrijf",
    note: "Bedrijfsinformatie",
    update: "Bijwerken",
    tabs: {
      companydetails: "Bedrijfsdetails",
      caterertype: "Type Cateraar",
      meal: "Maaltijden & Dieet",
      images: "Afbeeldingen",
    },
  },
  payment: {
    title: "Mijn Abonnementen",
    note: "Abonnementsbetalingsgeschiedenis",
    grid: {
      id: "Paypal ID",
      Image: "Afbeelding",
      status: "Status",
      subId: "Abonnement ID",
      pID: "Plan ID",
      Date: "Betaaldatum",
      eDate: "Vervaldatum",
      action: "Actie",
    },
    cancelPlan: "Weet je het zeker?",
    cancelPlanText: "Weet je zeker dat je je abonnement wilt annuleren?",
    cancel: "Annuleren",
    Confirm: "Bevestigen",
    successTitle: "Succes",
    successMessage: "Je plan is succesvol geannuleerd.",
    errorTitle: "Fout",
    errorMessage:
      "Er was een fout bij het annuleren van je plan. Probeer het opnieuw.",
    verify: "Verifiëren",
  },
  review: {
    title: "Klantbeoordelingen",
    note: "Beoordelingen",
    grid: {
      Profile: "Profiel",
      name: "Naam",
      Review: "Beoordeling",
      diplay: "Beoordeling Weergeven",
    },
  },
  booking: {
    title: "Mijn Bestellingen",
    note: "Huidige Bestellingen",
    grid: {
      Profile: "Profiel",
      name: "Gebruikersnaam",
      booking: "Besteldatum",
      eDate: "Einddatum",
      view: "Bekijken",
      action: "Actie",
      ViewDetail: "Details Bekijken",
    },
    myBooking: {
      title: "Mijn Bestelling",
      city: "Stadsnaam",
      add: "Adres",
      postal: "Postcode",
      Type: "Type Diëten",
      meal: "Type Maaltijd",
      date: "Datum",
      Contact: "Contactgegevens",
      person: "Personen",
      budget: "Budget per Persoon",
      amount: "Betaald Bedrag",
    },
  },
  order: {
    title: "Mijn Boekingen",
    note: "Huidige Boeking",
    grid: {
      Profile: "Profiel",
      name: "Gebruikersnaam",
      booking: "Boekingsdatum",
      eDate: "Einddatum",
      view: "Bekijken",
      action: "Actie",
      ViewDetail: "Details Bekijken",
    },
    myBooking: {
      title: "Mijn Boeking",
      city: "Stadsnaam",
      add: "Adres",
      postal: "Postcode",
      Type: "Type Diëten",
      meal: "Type Maaltijd",
      date: "Datum",
      Contact: "Contactgegevens",
      person: "Personen",
      budget: "Budget per Persoon",
      amount: "Betaald Bedrag",
    },
    caterer: {
      typeofcaterer: "Type Cateraar",
      moreaboutcompany: "Meer over Bedrijf",
    },
    meal: {
      categoryclass: "Categorische Indeling van Maaltijden",
      updatediet: "Je speciale dieet bijwerken?",
    },
  },
};



export const OwnerProfile_ES_trans = {
  myProfile: "Mi Perfil",
  title: "Panel de Socios",
  dash: {
    title: "Tablero",
    note: "Información de Reservas de Food Truck",
    latest: "Últimas Reservas",
    cards: {
      total: "Total",
      complete: "Aprobado",
      pending: "Pendiente",
      cancel: "Cancelado",
    },
    grid: {
      profile: "Perfil",
      booking: "Fecha de Reserva",
      name: "Nombre",
      eDate: "Fecha de Finalización",
    },
  },
  profile: {
    title: "Mi Perfil",
    note: "Información del Perfil",
    reset: "Restablecer Contraseña",
    company: "Imágenes de la Empresa",
    certi: "Certificado Esencial",
    update: "Actualizar",
  },
  company: {
    title: "Mi Empresa",
    note: "Información de la Empresa",
    update: "Actualizar",
    tabs: {
      companydetails: "Detalles de la Empresa",
      caterertype: "Tipo de Caterer",
      meal: "Comidas & Dietas",
      images: "Imágenes",
    },
  },
  payment: {
    title: "Mis Suscripciones",
    note: "Historial de Pagos de Suscripción",
    grid: {
      id: "ID de Paypal",
      Image: "Imagen",
      status: "Estado",
      subId: "ID de Suscripción",
      pID: "ID de Plan",
      Date: "Fecha de Pago",
      eDate: "Fecha de Expiración",
      action: "Acción",
    },
    cancelPlan: "¿Estás seguro?",
    cancelPlanText: "¿Estás seguro de que deseas cancelar tu suscripción?",
    cancel: "Cancelar",
    Confirm: "Confirmar",
    successTitle: "Éxito",
    successMessage: "Tu plan ha sido cancelado con éxito.",
    errorTitle: "Error",
    errorMessage: "Hubo un error al cancelar tu plan. Inténtalo de nuevo.",
    verify: "Verificar",
  },
  review: {
    title: "Reseñas de Clientes",
    note: "Reseñas",
    grid: {
      Profile: "Perfil",
      name: "Nombre",
      Review: "Reseña",
      diplay: "Mostrar Reseña",
    },
  },
  booking: {
    title: "Mis Pedidos",
    note: "Pedidos Actuales",
    grid: {
      Profile: "Perfil",
      name: "Nombre de Usuario",
      booking: "Fecha de Pedido",
      eDate: "Fecha de Finalización",
      view: "Ver",
      action: "Acción",
      ViewDetail: "Ver Detalles",
    },
    myBooking: {
      title: "Mi Pedido",
      city: "Nombre de la Ciudad",
      add: "Dirección",
      postal: "Código Postal",
      Type: "Tipo de Dietas",
      meal: "Tipo de Comida",
      date: "Fecha",
      Contact: "Detalles de Contacto",
      person: "Personas",
      budget: "Presupuesto por Persona",
      amount: "Cantidad Pagada",
    },
  },
  order: {
    title: "Mis Reservas",
    note: "Reserva Actual",
    grid: {
      Profile: "Perfil",
      name: "Nombre de Usuario",
      booking: "Fecha de Reserva",
      eDate: "Fecha de Finalización",
      view: "Ver",
      action: "Acción",
      ViewDetail: "Ver Detalles",
    },
    myBooking: {
      title: "Mi Reserva",
      city: "Nombre de la Ciudad",
      add: "Dirección",
      postal: "Código Postal",
      Type: "Tipo de Dietas",
      meal: "Tipo de Comida",
      date: "Fecha",
      Contact: "Detalles de Contacto",
      person: "Personas",
      budget: "Presupuesto por Persona",
      amount: "Cantidad Pagada",
    },
    caterer: {
      typeofcaterer: "Tipo de Caterer",
      moreaboutcompany: "Más sobre la Empresa",
    },
    meal: {
      categoryclass: "Clasificación Categórica de Comidas",
      updatediet: "¿Actualizar tu dieta especial?",
    },
  },
};
