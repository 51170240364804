import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hasToken, setHasToken] = useState(false);

  const redirectPath = children?.props?.pathname;

  useEffect(() => {
    const storedData = localStorage.getItem("Fiesta");
    if (storedData) {
      const { token } = JSON.parse(storedData);
      if (token) {
        setHasToken(true);
      }
    }
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  if (hasToken) {
    return children;
  }

  return <Navigate to="/login" state={{ path: redirectPath }} />;
};

export default PrivateRoute;
