export const Inquiry_EN_Trans = {
  diet: "Do you require a special diet?",
  date: "Select event date",
  person: "The size of my event",
};

export const Inquiry_DE_Trans = {
  diet: "Benötigen Sie eine spezielle Ernährungsform?",
  date: "Veranstaltungsdatum wählen",
  person: "Die Größe meiner Veranstaltung",
};
