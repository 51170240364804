import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCompanyId } from "../../Context/CompanyIdContext";
import LocalizationDropDown from "./LocalizationDropDown";
import { NavRoutes } from "./NavRoutes";

import logo from "../../Assets/images/logo.webp";
import home from "../../Assets/images/Mobile/home.webp";
import partner from "../../Assets/images/Mobile/partner.webp";
import foodtruck from "../../Assets/images/Mobile/food-truck.webp";
import about from "../../Assets/images/Mobile/about.webp";
import chat from "../../Assets/images/Mobile/chat.webp";
import reach from "../../Assets/images/Mobile/reachus.webp";
import profile from "../../Assets/images/Mobile/user.webp";
import login from "../../Assets/images/Mobile/login.webp";

const TemporaryDrawer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggleRender, setCompanyId } = useCompanyId();

  const [open, setOpen] = useState(false);
  const [loginID, setLoginID] = useState(null);
  const [role, setRole] = useState(null);

  const toggleDrawer = (state) => () => setOpen(state);

  const logout = () => {
    localStorage.clear();
    window.dispatchEvent(
      new CustomEvent("authUpdate", { detail: { isLoggedIn: false } })
    );
    toast.success(t("res.logout"));
    navigate(NavRoutes.HOME);
    toggleRender();
    setCompanyId(null);
  };

  useEffect(() => {
    const updateAuthState = () => {
      const data = JSON.parse(localStorage.getItem("Fiesta"));
      setLoginID(data?.id || null);
      setRole(data ? Number(data.role) : null);
    };

    updateAuthState();

    const handleAuthUpdate = (event) => {
      event.detail.isLoggedIn ? updateAuthState() : setLoginID(null);
    };

    window.addEventListener("authUpdate", handleAuthUpdate);
    return () => window.removeEventListener("authUpdate", handleAuthUpdate);
  }, [toggleRender]);

  const getMenuItems = () => {
    const items = [
      { label: t("Navbar.Home"), path: NavRoutes.HOME, icon: home },
      {
        label: t("Navbar.FoodTrucks"),
        path: `${NavRoutes.TRUCKS}/1`,
        icon: foodtruck,
      },
      role === 2
        ? {
            label: t("Navbar.mytrucks"),
            path: NavRoutes.MY_COMPANY,
            icon: partner,
          }
        : {
            label: t("Navbar.BecomeAPartner"),
            path: NavRoutes.PARTNER,
            icon: partner,
          },
      { label: t("Navbar.AboutUs"), path: NavRoutes.ABOUT, icon: about },
      { label: t("Navbar.chat"), path: NavRoutes.CHAT, icon: chat },
      { label: t("Navbar.ReachUs"), path: NavRoutes.REACH, icon: reach },
    ];

    if (loginID) {
      items.push(
        {
          label: t("Navbar.Profile"),
          path: role === 1 ? NavRoutes.PROFILE : NavRoutes.OWNER_PROFILE,
          icon: profile,
        },
        { label: t("Navbar.Logout"), path: NavRoutes.LOGOUT, icon: login }
      );
    } else {
      items.push({
        label: t("Navbar.Login"),
        path: NavRoutes.LOGIN,
        icon: login,
      });
    }

    return items;
  };

  const renderDrawerList = () => (
    <Box>
      <Box sx={{ width: "100%", py: 1 }} className="flexCenter">
        <img src={logo} alt="logo" height={80} width={120} />
      </Box>
      <Box sx={{ width: 250, height: "calc(100vh - 90px)", overflow: "auto" }}>
        <List>
          {getMenuItems().map(({ label, path, icon }) => (
            <ListItem key={label} disablePadding>
              <ListItemButton
                onClick={() =>
                  path === NavRoutes.LOGOUT ? logout() : navigate(path)
                }
              >
                <ListItemIcon>
                  <img src={icon} alt={label} height={24} width={24} />
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  style={{
                    color: theme.palette.text.defaultDark,
                    fontWeight: 500,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
          <LocalizationDropDown />
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Button onClick={toggleDrawer(true)}>
        <MenuRoundedIcon style={{ fontWeight: 600 }} />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {renderDrawerList()}
      </Drawer>
    </>
  );
};

export default TemporaryDrawer;
