export const Register_EN_Trans = {
  register: "Register",
  label: {
    country: "Country",
    name: "Full Name",
    dob: "Date of birth",
    email: "Email",
    number: "Mobile Number",
    password: "Password",
    confirm: "Confirm Password",
  },
  email: "Enter Email",
  password: "Enter Password",
  repssword: "Re-Enter your password",
  understand: "I Understand the",
  terms: "Terms of Service",
  and: "and",
  privacy: " Privacy Policy.",
  already: "Already have an account?",
  fullname: "Enter your full name",
  number: "Enter your number",
  selectcountry: "Select your country",
  loginhere: "Login here",
  profileImageRequired: "Profile Image is required",
  invalidEmail: "Invalid email",
  emailRequired: "Email is required",
  passwordRequired: "Password is required",
  passwordMin: "Password must be at least 6 characters.",
  passwordMax: "Password must be at most 20 characters.",
  confirmPasswordRequired: "Confirm password is required",
  passwordsMustMatch: "Passwords must match",
  countryRequired: "Country is required",
  validCountry: "Please select a valid country.",
  fullNameRequired: "Full name is required",
  dobRequired: "Date of birth is required",
  numberRequired: "Number is required",
  Required: "Required",
  onlyNumbers: "Only numbers are allowed",
  mustAgreeToTerms: "You must agree to the terms and conditions",
  selectmeals: "Select Meals",
  isReaded:
    "I have read and accepted the Terms and Conditions and Privacy Policy",
};

export const Register_NL_Trans = {
  register: "Registreren",
  email: "Voer e-mail in",
  password: "Voer wachtwoord in",
  repssword: "Voer uw wachtwoord opnieuw in",
  understand: "Ik begrijp de",
  terms: "Servicevoorwaarden",
  and: "en",
  privacy: "Privacybeleid.",
  already: "Heeft u al een account?",
  fullname: "Voer uw volledige naam in",
  number: "Voer uw telefoonnummer in",
  selectcountry: "Selecteer uw land",
  loginhere: "Hier inloggen",
  profileImageRequired: "Profielfoto is verplicht",
  invalidEmail: "Ongeldige e-mail",
  emailRequired: "E-mail is verplicht",
  passwordRequired: "Wachtwoord is verplicht",
  passwordMin: "Wachtwoord moet minimaal 6 tekens bevatten.",
  passwordMax: "Wachtwoord mag maximaal 20 tekens bevatten.",
  confirmPasswordRequired: "Bevestiging van wachtwoord is verplicht",
  passwordsMustMatch: "Wachtwoorden moeten overeenkomen",
  countryRequired: "Land is verplicht",
  validCountry: "Selecteer een geldig land.",
  fullNameRequired: "Volledige naam is verplicht",
  dobRequired: "Geboortedatum is verplicht",
  numberRequired: "Nummer is verplicht",
  onlyNumbers: "Alleen cijfers zijn toegestaan",
  mustAgreeToTerms: "U moet akkoord gaan met de voorwaarden",
  selectmeals: "Selecteer maaltijden",
};

export const Register_DE_Trans = {
  label: {
    country: "Land",
    name: "Vollständiger Name",
    dob: "Geburtsdatum",
    email: "E-Mail",
    number: "Handynummer",
    password: "Passwort",
    confirm: "Passwort bestätigen",
  },

  register: "Registrieren",
  email: "E-Mail eingeben",
  password: "Passwort eingeben",
  repssword: "Passwort erneut eingeben",
  understand: "Ich verstehe die",
  terms: "Servicebedingungen",
  and: "und",
  privacy: "Datenschutzrichtlinie.",
  already: "Haben Sie bereits ein Konto?",
  fullname: "Geben Sie Ihren vollständigen Namen ein",
  number: "Geben Sie Ihre Telefonnummer ein",
  selectcountry: "Wählen Sie Ihr Land",
  loginhere: "Hier einloggen",
  profileImageRequired: "Profilbild ist erforderlich",
  invalidEmail: "Ungültige E-Mail",
  emailRequired: "E-Mail ist erforderlich",
  passwordRequired: "Passwort ist erforderlich",
  passwordMin: "Das Passwort muss mindestens 6 Zeichen lang sein.",
  passwordMax: "Das Passwort darf maximal 20 Zeichen lang sein.",
  confirmPasswordRequired: "Bestätigung des Passworts ist erforderlich",
  passwordsMustMatch: "Die Passwörter müssen übereinstimmen",
  countryRequired: "Land ist erforderlich",
  validCountry: "Wählen Sie ein gültiges Land.",
  fullNameRequired: "Vollständiger Name ist erforderlich",
  dobRequired: "Geburtsdatum ist erforderlich",
  numberRequired: "Nummer ist erforderlich",
  Required: "Erforderlich",
  onlyNumbers: "Es sind nur Zahlen erlaubt",
  mustAgreeToTerms: "Sie müssen den Bedingungen zustimmen",
  selectmeals: "Wählen Sie Mahlzeiten",
  isReaded:
    "Ich habe die AGB´s und Datenschutzbestimmungen gelesen und zur Kenntnis genommen",
};

export const Register_ES_Trans = {
  register: "Registrarse",
  email: "Ingrese Correo Electrónico",
  password: "Ingrese Contraseña",
  repssword: "Reingrese su contraseña",
  understand: "Entiendo los",
  terms: "Términos de Servicio",
  and: "y",
  privacy: "Política de Privacidad.",
  already: "¿Ya tienes una cuenta?",
  fullname: "Ingrese su nombre completo",
  number: "Ingrese su número",
  selectcountry: "Seleccione su país",
  loginhere: "Iniciar sesión aquí",
  profileImageRequired: "Se requiere imagen de perfil",
  invalidEmail: "Correo electrónico no válido",
  emailRequired: "El correo electrónico es obligatorio",
  passwordRequired: "La contraseña es obligatoria",
  passwordMin: "La contraseña debe tener al menos 6 caracteres.",
  passwordMax: "La contraseña debe tener como máximo 20 caracteres.",
  confirmPasswordRequired: "Se requiere confirmar la contraseña",
  passwordsMustMatch: "Las contraseñas deben coincidir",
  countryRequired: "El país es obligatorio",
  validCountry: "Por favor, seleccione un país válido.",
  fullNameRequired: "El nombre completo es obligatorio",
  dobRequired: "La fecha de nacimiento es obligatoria",
  numberRequired: "El número es obligatorio",
  onlyNumbers: "Solo se permiten números",
  mustAgreeToTerms: "Debe aceptar los términos y condiciones",
  selectmeals: "Seleccione comidas",
};
