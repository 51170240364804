export const MyProfile_EN_trans = {
  title: "My Profile",
  logout: "Log Out",
 
  reset: "Reset Password",
  update: "Update",
  menu: "Menu",
  profile: "Profile Image is required",
  country: {
    required: "Country is required",
    invalid: "Please select a valid country.",
  },
  fullname: "Full name is required",

  dob: "Date of birth is required",
  phno: {
    required: "Number is required",

    invalid: "Only numbers are allowed",
  },
};

export const MyProfile_DE_trans = {
  title: "Mein Profil",
  logout: "Abmelden",
  menu: 'Speisekarte',
  reset: "Passwort zurücksetzen",
  update: "Aktualisieren",
  profile: "Profilbild ist erforderlich",
  country: {
    required: "Land ist erforderlich",
    invalid: "Bitte wählen Sie ein gültiges Land.",
  },
  fullname: "Vollständiger Name ist erforderlich",
  dob: "Geburtsdatum ist erforderlich",
  phno: {
    required: "Nummer ist erforderlich",
    invalid: "Es sind nur Zahlen erlaubt",
  },
};


export const MyProfile_NL_trans = {
  title: "Mijn Profiel",
  logout: "Uitloggen",
  reset: "Wachtwoord Resetten",
  update: "Bijwerken",
  profile: "Profielafbeelding is verplicht",

  country: {
    required: "Land is verplicht",

    invalid: "Selecteer een geldig land.",
  },
  fullname: "Volledige naam is verplicht",

  dob: "Geboortedatum is verplicht",

  phno: {
    required: "Nummer is verplicht",
    invalid: "Alleen cijfers zijn toegestaan",
  },
};

export const MyProfile_ES_trans = {
  title: "Mi Perfil",
  logout: "Cerrar sesión",
  reset: "Restablecer contraseña",
  update: "Actualizar",
  profile: "Se requiere imagen de perfil",
  country: {
    required: "Se requiere país",
    invalid: "Por favor, selecciona un país válido.",
  },
  fullname: "Se requiere nombre completo",
  dob: "Se requiere fecha de nacimiento",
  phno: {
    required: "Se requiere número",
    invalid: "Solo se permiten números",
  },
};

