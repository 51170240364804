export const Faq_EN_trans = {
  faq: "FAQs",
  noDAta: "No Data Found",
};

export const Faq_DE_trans = {
  faq: "Häufig gestellte Fragen",
  noDAta: "Keine Daten gefunden",
};

export const Faq_NL_trans = {
  faq: "Veelgestelde vragen",
  noDAta: "Geen gegevens gevonden",
};


export const Faq_ES_trans = {
  faq: "Preguntas Frecuentes",
  noData: "No se encontraron datos",
};
