export const ReachUs_EN_trans = {
    contactUs: "Contact Us",
    fullname: 'Enter full name',
    email: 'Enter email',
    subject: 'Enter subject',
    desc: 'Enter description',
    number: "Enter number",
    btn:"Submit",
    fiesta:"FIESTA CATERING",
}

export const ReachUs_DE_trans = {
    contactUs: "Kontaktiere uns",
    fullname: 'Vollständigen Namen eingeben',
    email: 'E-Mail eingeben',
    subject: 'Betreff eingeben',
    desc: 'Beschreibung eingeben',
    number: "Nummer eingeben",
    btn: "Absenden",
    fiesta: "FIESTA CATERING",
};


export const ReachUs_NL_trans = {
    contactUs: "Neem contact met ons op",
    fullname: "Voer volledige naam in",
    email: "Voer e-mail in",
    subject: "Voer onderwerp in",
    desc: "Voer beschrijving in",
    number: "Voer nummer in",
    btn: "Boek nu",
    fiesta: "FEESTA CATERING",
}

export const ReachUs_ES_trans = {
    contactUs: "Contáctanos",
    fullname: 'Ingresa tu nombre completo',
    email: 'Ingresa tu correo electrónico',
    subject: 'Ingresa el asunto',
    desc: 'Ingresa la descripción',
    number: "Ingresa tu número",
    btn: "Reservar Ahora",
    fiesta: "FIESTA CATERING",
};




