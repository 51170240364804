// import React, { useEffect, useState } from "react";
// import HomeSection from "./Sections/HomeSection";
// import SearchSection from "./Sections/SearchSection";
// import ExploreSection from "./Sections/ExploreSection";
// import CateringSection from "./Sections/CateringSection";
// import MenuSection from "./Sections/MenuSection";
// import AboutSection from "./Sections/AboutSection";
// import CateringServiceSection from "./Sections/CateringServicesSection";
// import EventandcatSection from "./Sections/EventandcatSection";
// import Subscription from "./Sections/Subscription";
// import { API_GET_USERID, API_POST } from "../../API/config";
// import { API_endpoints } from "../../API/API_Endpoints";
// import { useNavigate } from "react-router-dom";
// import { NavRoutes } from "../../Component/Navbar/NavRoutes";
// import { toast } from "react-toastify";
// import NewsletterBox from "./Sections/Newsletter";
// import { useTranslation } from "react-i18next";
// import RenewSubsriptionModal from "../../Component/RenewSubscriptionModal/RenewSubscriptionModal";

// const HomePage = () => {
//   const userData = JSON.parse(localStorage.getItem("Fiesta"));
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const [NewsApiCalled, setNewsApiCalled] = useState(false);
//   const [isSubsribed, setIsSubsribed] = useState(false);
//   const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
//   const [subType, setSubType] = useState("");

//   useEffect(() => {
//     window.scrollTo({ behavior: "instant", top: 0 });
//   }, []);

//   const getDetails = async () => {
//     try
//     {
//       let res = await API_GET_USERID(API_endpoints.usergetbyid, userData?.id);
//       if (res?.s)
//       {
//         const x = res?.r?.company_details?.subscription ? true : false;
//         let isExpired =
//           // res?.r?.company_details?.subscription?.status === "ACTIVE";
//           res?.r?.company_details?.subscription?.status === "EXPIRED";
//         setIsSubscriptionExpired(isExpired);
//         setSubType(
//           res?.r?.company_details?.subscription?.plan_id ===
//             "F-TRIAL31122024LAIRT"
//             ? "Trail"
//             : ""
//         );
//         setIsSubsribed(x);
//       }
//     } catch (error)
//     {
//       console.log(error);
//     }
//   };

//   const urlParams = new URLSearchParams(window.location.search);
//   const NewsLetterEmailSubscribe = urlParams.get("newsletter-subscribe");
//   const NewsLetterEmailUnsubscribe = urlParams.get("newsletter-unsubscribe");
//   const NewsLetterType = urlParams.get("type");

//   const handleSubscribeNewsLeter = async (email, userType) => {
//     try
//     {
//       const URL = NewsLetterEmailSubscribe
//         ? API_endpoints.subscribeNewsLetter
//         : API_endpoints.UnSubscribeNewsLetter;
//       let res = await API_POST(URL, {
//         email: email,
//         type: userType ?? null,
//       });

//       if (res?.s)
//       {
//         setNewsApiCalled(true);
//         toast.success(t("res.success"));
//       } else
//       {
//         toast.info(res?.m);
//       }
//       navigate(NavRoutes.HOME);
//     } catch (error)
//     {
//       console.log(error);
//       navigate(NavRoutes.HOME);
//     }
//   };

//   useEffect(() => {
//     if (!NewsApiCalled)
//     {
//       if (NewsLetterEmailSubscribe && NewsLetterType)
//       {
//         handleSubscribeNewsLeter(NewsLetterEmailSubscribe, NewsLetterType);
//       } else if (NewsLetterEmailUnsubscribe)
//       {
//         handleSubscribeNewsLeter(NewsLetterEmailUnsubscribe);
//       }
//     }
//   }, [NewsApiCalled]);

//   useEffect(() => {
//     userData && getDetails();
//   }, []);

//   return (
//     <>
//       <HomeSection />
//       <SearchSection />
//       <ExploreSection />
//       {userData && userData.role === 2 && isSubsribed && <Subscription />}
//       <CateringSection />
//       <CateringServiceSection />
//       <MenuSection />
//       <AboutSection />
//       <EventandcatSection />
//       <NewsletterBox />
//       <RenewSubsriptionModal
//         open={isSubscriptionExpired}
//         setOpen={setIsSubscriptionExpired}
//         subType={subType}
//       />
//     </>
//   );
// };

// export default HomePage;

import React, { useEffect, useState, Suspense } from "react";
import { Box } from "@mui/material";
import { API_GET_USERID, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import RenewSubsriptionModal from "../../Component/RenewSubscriptionModal/RenewSubscriptionModal";
import Loader from "../../Component/Loader/Loader";
import AppLoader from "../../Component/Loader/AppLoader";

// Lazy loaded components
const HomeSection = React.lazy(() => import("./Sections/HomeSection"));
const SearchSection = React.lazy(() => import("./Sections/SearchSection"));
const ExploreSection = React.lazy(() => import("./Sections/ExploreSection"));
const CateringSection = React.lazy(() => import("./Sections/CateringSection"));
const MenuSection = React.lazy(() => import("./Sections/MenuSection"));
const AboutSection = React.lazy(() => import("./Sections/AboutSection"));
const CateringServiceSection = React.lazy(() =>
  import("./Sections/CateringServicesSection")
);
const EventandcatSection = React.lazy(() =>
  import("./Sections/EventandcatSection")
);
const Subscription = React.lazy(() => import("./Sections/Subscription"));
const NewsletterBox = React.lazy(() => import("./Sections/Newsletter"));

const HomePage = () => {
  const userData = JSON.parse(localStorage.getItem("Fiesta"));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [NewsApiCalled, setNewsApiCalled] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [subType, setSubType] = useState("");

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const getDetails = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, userData?.id);
      if (res?.s)
      {
        const isActive = res?.r?.company_details?.subscription ? true : false;
        let isExpired =
          res?.r?.company_details?.subscription?.status === "EXPIRED";
        setIsSubscriptionExpired(isExpired);
        setSubType(
          res?.r?.company_details?.subscription?.plan_id ===
            "F-TRIAL31122024LAIRT"
            ? "Trial"
            : ""
        );
        setIsSubscribed(isActive);
      }
    } catch (error)
    {
      console.error(error);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const NewsLetterEmailSubscribe = urlParams.get("newsletter-subscribe");
  const NewsLetterEmailUnsubscribe = urlParams.get("newsletter-unsubscribe");
  const NewsLetterType = urlParams.get("type");

  const handleSubscribeNewsLetter = async (email, userType) => {
    try
    {
      const URL = NewsLetterEmailSubscribe
        ? API_endpoints.subscribeNewsLetter
        : API_endpoints.UnSubscribeNewsLetter;
      let res = await API_POST(URL, {
        email: email,
        type: userType ?? null,
      });

      if (res?.s)
      {
        setNewsApiCalled(true);
        toast.success(t("res.success"));
      } else
      {
        toast.info(res?.m);
      }
      navigate(NavRoutes.HOME);
    } catch (error)
    {
      console.error(error);
      navigate(NavRoutes.HOME);
    }
  };

  useEffect(() => {
    if (!NewsApiCalled)
    {
      if (NewsLetterEmailSubscribe && NewsLetterType)
      {
        handleSubscribeNewsLetter(NewsLetterEmailSubscribe, NewsLetterType);
      } else if (NewsLetterEmailUnsubscribe)
      {
        handleSubscribeNewsLetter(NewsLetterEmailUnsubscribe);
      }
    }
  }, [NewsApiCalled]);

  useEffect(() => {
    userData && getDetails();
  }, []);

  return (
    <Suspense fallback={<Box
      className="flexCenter"
      sx={{ height: "calc(100vh - 200px)", width: "100%" }}
    >
      <AppLoader />
    </Box>}>
      <HomeSection />
      <SearchSection />
      <ExploreSection />
      {userData && userData.role === 2 && isSubscribed && <Subscription />}
      <CateringSection />
      <CateringServiceSection />
      <MenuSection />
      <AboutSection />
      <EventandcatSection />
      <NewsletterBox />
      <RenewSubsriptionModal
        open={isSubscriptionExpired}
        setOpen={setIsSubscriptionExpired}
        subType={subType}
      />
    </Suspense>
  );
};

export default HomePage;
