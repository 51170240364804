export const BookNow_EN_trans = {
  title: "Booking Form",
  required: "Required",
  city: { title: "Enter City Name", pl: "Enter City Name" },
  postal_code: { title: "Enter Postal Code", pl: "Enter Postal Code" },
  address: { title: "Enter Address", pl: "Enter Address" },
  catering_Type: "Choose type of meals",
  diet: "Do you require diet?",
  date: "Select Date",
  Vali_startDate: "Start date cannot be in the past",
  Vali_endDate: "End date must be after start date",
  contact: "Add contact details",
  full_name: "Enter your full name",
  email: "Enter your email",
  number: "Enter your number",
  person: "Add Persons",
  persons: "Persons",
  budgetPerPerson: "Budget per person",
  total: "Total Budget",
  button: "Book Now",
  inValid: "Invalid",
  LogInRequired: "Login required",
};

export const BookNow_DE_trans = {
  title: "Buchungsformular",
  required: "Erforderlich",
  city: { title: "Stadtname eingeben", pl: "Stadtname eingeben" },
  postal_code: { title: "Postleitzahl eingeben", pl: "Postleitzahl eingeben" },
  address: { title: "Adresse eingeben", pl: "Adresse eingeben" },
  catering_Type: "Wählen Sie die Art der Mahlzeiten",
  diet: "Benötigen Sie eine Diät?",
  date: "Datum auswählen",
  Vali_startDate: "Das Startdatum kann nicht in der Vergangenheit liegen",
  Vali_endDate: "Das Enddatum muss nach dem Startdatum liegen",
  contact: "Kontaktinformationen hinzufügen",
  full_name: "Geben Sie Ihren vollständigen Namen ein",
  email: "Geben Sie Ihre E-Mail ein",
  number: "Geben Sie Ihre Nummer ein",
  person: "Personen hinzufügen",
  persons: "Personen",
  budgetPerPerson: "Budget pro Person",
  total: "Gesamtbudget",
  button: "Jetzt buchen",
  inValid: "Ungültig",
  LogInRequired: "Anmeldung erforderlich",
};

export const BookNow_NL_trans = {
  title: "Boekingsformulier",
  required: "Verplicht",
  city: { title: "Voer de stadsnaam in", pl: "Voer de stadsnaam in" },
  postal_code: { title: "Voer de postcode in", pl: "Voer de postcode in" },
  address: { title: "Voer het adres in", pl: "Voer het adres in" },
  catering_Type: "Kies het type maaltijd",
  diet: "Heeft u dieetwensen?",
  date: "Selecteer een datum",
  Vali_startDate: "Startdatum mag niet in het verleden liggen",
  Vali_endDate: "Einddatum moet na de startdatum liggen",
  contact: "Voeg contactgegevens toe",
  full_name: "Voer uw volledige naam in",
  email: "Voer uw e-mailadres in",
  number: "Voer uw nummer in",
  person: "Voeg personen toe",
  persons: "Personen",
  budgetPerPerson: "Budget per persoon",
  total: "Totale budget",
  button: "Boek nu",
  inValid: "Ongeldig",
  LogInRequired: "Inloggen vereist",
};

export const BookNow_ES_trans = {
  title: "Formulario de Reserva",
  required: "Requerido",
  city: {
    title: "Ingresa el Nombre de la Ciudad",
    pl: "Ingresa el Nombre de la Ciudad",
  },
  postal_code: {
    title: "Ingresa el Código Postal",
    pl: "Ingresa el Código Postal",
  },
  address: { title: "Ingresa la Dirección", pl: "Ingresa la Dirección" },
  catering_Type: "Elige el tipo de comidas",
  diet: "¿Requiere dieta?",
  date: "Selecciona la Fecha",
  Vali_startDate: "La fecha de inicio no puede ser en el pasado",
  Vali_endDate:
    "La fecha de finalización debe ser después de la fecha de inicio",
  contact: "Agregar detalles de contacto",
  full_name: "Ingresa tu nombre completo",
  email: "Ingresa tu correo electrónico",
  number: "Ingresa tu número",
  person: "Agregar Personas",
  persons: "Personas",
  budgetPerPerson: "Presupuesto por persona",
  total: "Presupuesto Total",
  button: "Reservar Ahora",
  inValid: "Inválido",
  LogInRequired: "Se requiere iniciar sesión",
};
