export const Subscription_EN_Trans = {
    Headline: "Subscription Details",

    SubHeadline:
        "It is a long established fact that a reader will be distracted by the readable",
    plan: "Plan",
    desc: "Description",
    sDate: "Start Date",
    eDate: "End Date",
    status:"Status",
    pendig:"Approval Pending",
    active:"Active",
    expired:"Expired",
    cancel:"Cancelled",
    month:"month",
    year:"year"
};

export const Subscription_DE_Trans = {
    Headline: "Abonnementdetails",

    SubHeadline:
        "Es ist eine allgemein anerkannte Tatsache, dass ein Leser durch den lesbaren Inhalt abgelenkt wird.",
    plan: "Plan",
    desc: "Beschreibung",
    sDate: "Startdatum",
    eDate: "Enddatum",
    status: "Status",
    pendig: "Genehmigung ausstehend",
    active: "Aktiv",
    expired: "Abgelaufen",
    month: "Monat",
    year: "Jahr"

};

export const Subscription_NL_Trans = {
    Headline: "Abonnementsgegevens",

    SubHeadline:
        "Het is een bekend gegeven dat een lezer wordt afgeleid door de leesbare inhoud.",
    plan: "Plan",
    desc: "Beschrijving",
    sDate: "Startdatum",
    eDate: "Einddatum",
};

export const Subscription_ES_trans = {
    Headline: "Detalles de Suscripción",

    SubHeadline:
        "Es un hecho establecido desde hace mucho tiempo que un lector se distraerá con lo legible",
    plan: "Plan",
    desc: "Descripción",
    sDate: "Fecha de Inicio",
    eDate: "Fecha de Finalización",
};


