import { Box } from "@mui/material";
import React from "react";
import { CircularProgress } from "@mui/material";
function Loader() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <CircularProgress style={{ color: "#f57c00" }} />
    </Box>
  );
}

export default Loader;

