import { createTheme } from "@mui/material/styles";
import { LightPallete } from "./Pallete";

export const theme = (mode = "light") => {
  return createTheme({
    palette: {
      mode: mode,
      ...(mode === "light" && LightPallete),
    },
  });
};
