import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import AllRoutes from "./Routes/AllRoutes";

function App() {
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
    import("animate.css/animate.min.css");
    import("react-toastify/dist/ReactToastify.css");
  }, []);

  return (
    <BrowserRouter>
      <AllRoutes />
      
    </BrowserRouter>
  );
}

export default App;
