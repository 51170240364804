export const Imprint_EN_Trans = {
  title: "Fiesta Catering",
  address: "Address",
  add: 'Information according to § 5 TMG',
  commercialRegister: "Commercial Register",
  vatId: "Sales tax identification number according to § 27a UstG",
  representedBy: "Represented by",
  managingDirector: "Managing Director",
  contact: "Contact",
  email: "Email",
  phone: "Phone",
  professionalLiabilityInsurance: "Professional liability insurance",
  euDisputeResolution: "EU dispute resolution",
  consumerDisputeResolution:
    "Consumer dispute resolution / universal arbitration board",
  insuranceScope: "Scope",
  imageRights: "Image rights",
  disclaimer: 'Disclaimer',
};

export const Imprint_DE_Trans = {
  title: "Fiesta Catering",
  address: "Adresse",
  add: 'Angaben gemäß § 5 TMG',
  commercialRegister: "Handelsregister",
  vatId: "Umstatzsteuer-Identifikationsnummer gemäß § 27a UstG",
  representedBy: "Vertreten durch",
  managingDirector: "Geschäftsführer",
  contact: "Kontakt",
  email: "E-Mail",
  phone: "Telefon",
  professionalLiabilityInsurance: "Berufshaftpflichtversicherung",
  euDisputeResolution: "EU-Streitbeilegung",
  consumerDisputeResolution:
    "Verbraucherstreitbeilegung / Universalschlichtungsstelle",
  insuranceScope: "Geltungsbereich",
  imageRights: "Bildrechte",
  disclaimer: 'Haftungsauschluss',
};
