export const Menu_En_trans = {
  menutitle: "Our Food Concepts at a Glance",
  subtitle:
    "It is a long established fact that a reader will be distracted by the readable",
  burgermenu: "Burger",
  pizzamenu: "Pasta",
  icecreammenu: "Sweets & Candy",
  bbqmenu: "Finger Food & Snacks",
};

export const Menu_DE_trans = {
  menutitle: "Unsere Food-Konzepte im Überblick",
  subtitle:
    "Es ist eine allgemein bekannte Tatsache, dass ein Leser abgelenkt wird von den lesbaren Inhalten",
  burgermenu: "Burger",
  pizzamenu: "Pasta",
  icecreammenu: "Sweets & Candy",
  bbqmenu: "Fingerfood & Snacks",
};

export const Menu_NL_trans = {
  menutitle: "Kies een Food Truck van het Menu",
  subtitle:
    "Het is een algemeen bekend feit dat een lezer zal worden afgeleid door de leesbare inhoud",
  burgermenu: "Burger Truck Menu",
  pizzamenu: "Pizza Truck Menu",
  icecreammenu: "IJs Truck Menu",
  bbqmenu: "BBQ Truck Menu",
};

export const Menu_ES_trans = {
  menutitle: "Nuestros conceptos de comida en resumen",
  subtitle:
    "Es un hecho bien conocido que un lector se distrae con el contenido legible",
  burgermenu: "Hamburguesa",
  pizzamenu: "Café",
  icecreammenu: "Dulces y caramelos",
  bbqmenu: "Comida para picar y snacks",
};
