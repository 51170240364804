import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./Localization/Localization.jsx";
import { ToastContainer, Zoom } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
    <ToastContainer
      position="bottom-left"
      theme="colored"
      transition={Zoom}
      draggable
      closeOnClick
    />
  </>
);
