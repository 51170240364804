export const CatService_EN_Trans = {
  title: "Fiesta Catering Concepts",
  subtitle:
    "It is a long-established fact that a reader will be distracted by readable content",
  btn: "Show More",
  cateringData: {
    card1: "Street Food Festivals",
    desc1: `“Experience the ultimate flavor trip!”
At our street food festivals, your city becomes a hotspot for street food lovers. Colorful food trucks, exotic aromas, and a variety of treats leave nothing to be desired! A feast for the senses and an event that brings people together!
`,
    card2: "Corporate Events",
    desc2: `“An unforgettable day for your team!”
Whether as a thank you for your employees, an anniversary celebration, or a product launch – our food trucks make your corporate event a highlight! Let your team enjoy and be motivated together – because everything tastes better together!
`,
    card3: "Private Parties",
    desc3: `“Celebrate the way you like it!”
Weddings, birthdays, or Christmas – special occasions deserve special enjoyment. This makes your celebration a culinary experience that excites everyone and creates lasting memories!
`,
  },
  company: {
    title: "Our Top Caterers",
    view: "View",
    subtitle:
      "Favorites of our community! These caterers have been highly praised by our customers for their outstanding service and creative delicacies.",
  },
};

export const CatService_NL_trans = {
  title: "Ontdek onze Cateringdiensten",
  subtitle:
    "Het is een algemeen bekend feit dat een lezer zal worden afgeleid door de leesbare inhoud",
  btn: "Bekijk Meer",
  cateringData: {
    card1: "Zakelijke Catering",
    desc1:
      "Professionele cateringdiensten voor bedrijfsevenementen met een verscheidenheid aan menuopties.",
    card2: "Bruiloft Catering",
    desc2:
      "Elegante cateringdiensten voor bruiloften met aanpasbare menu's en thema's voor uw speciale dag.",
    card3: "Privé Catering",
    desc3:
      "Exclusieve catering voor privéfeesten met gepersonaliseerde voedsel- en drankmenu's om uw evenement onvergetelijk te maken.",
  },
};

export const CatService_DE_Trans = {
  title: "Fiesta Catering Konzepte",
  subtitle:
    "Es ist eine lang etablierte Tatsache, dass ein Leser von Lesbarem abgelenkt wird",
  btn: "Mehr anzeigen",
  cateringData: {
    card1: "Streetfood festivals",
    desc1: `„Erleben Sie den ultimativen Geschmackstrip!“
Auf unseren Streetfoodfestivals wird Ihre Stadt zum Hotspot für Streetfood-Liebhaber. Bunte Foodtrucks, exotische Aromen und eine Vielfalt an Leckereien lassen keinen Wunsch offen! Ein Fest für die Sinne und ein Event, das zusammenbringt!
`,
    card2: "Firmen Events",
    desc2: `„Ein unvergesslicher Tag für Ihr Team!“
Ob als Dankeschön für Ihre Mitarbeiter, zum Firmenjubiläum oder zur Produkteinführung – mit unseren Foodtrucks wird Ihr Firmenevent zum Highlight! Lassen Sie Ihr Team gemeinsam genießen und motivieren – denn zusammen schmeckt’s einfach besser!
`,
    card3: "Private Feiern",
    desc3: `„Feiern Sie, wie es Ihnen schmeckt!“
Hochzeiten, Geburtstage oder Weihnachten – besondere Anlässe verdienen besonderen Genuss. So wird Ihre Feier zum kulinarischen Erlebnis, das alle begeistert und an das man sich gern erinnert!
`,
  },
  company: {
    title: "Unsere top Caterer",
    view: "Ansicht",
    subtitle:
      "Die Favoriten unserer Community! Diese Caterer wurden von unseren Kunden für ihren herausragenden Service und ihre kreativen Köstlichkeiten besonders gelobt.",
  },
};

export const CatService_ES_Trans = {
  title: "Conceptos de Catering Fiesta",
  subtitle:
    "Es un hecho ampliamente conocido que el lector se distrae con el contenido legible",
  btn: "Ver más",
  cateringData: {
    card1: "Festivales de Street Food",
    desc1: `“¡Experimenta el viaje de sabor definitivo!”
En nuestros festivales de street food, tu ciudad se convierte en un punto de encuentro para los amantes de la comida callejera. Coloridos food trucks, aromas exóticos y una variedad de delicias que cumplen todos los deseos. ¡Una fiesta para los sentidos y un evento que une a todos!
`,
    card2: "Eventos Corporativos",
    desc2: `“Un día inolvidable para tu equipo!”
Ya sea como un agradecimiento para tus empleados, una celebración de aniversario o el lanzamiento de un producto, ¡nuestros food trucks harán que tu evento corporativo sea inolvidable! Deja que tu equipo disfrute y se motive en conjunto, ¡porque juntos todo sabe mejor!
`,
    card3: "Fiestas Privadas",
    desc3: `“¡Celebra como te gusta!”
Bodas, cumpleaños o Navidad, las ocasiones especiales merecen un disfrute especial. Así tu celebración se convierte en una experiencia culinaria que entusiasma a todos y deja recuerdos inolvidables.
`,
  },
  company: {
    title: "Nuestros Mejores Caterers",
    subtitle:
      "¡Los favoritos de nuestra comunidad! Estos caterers han sido altamente elogiados por nuestros clientes por su servicio excepcional y sus delicias creativas.",
  },
};
