import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { English } from "./English";
import { German } from "./German";
// import { Spanish } from "./Spanish";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: "en",
    debug: true,
    fallbackLng: "en",
    resources: {
      en: English,
      de: German,
      // se: Spanish,
    },

    interpolation: { escapeValue: false },
  });

export default i18next;
