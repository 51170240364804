import { Box, Grid2, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import food_truck from "../../Assets/images/footer_truck.webp";
import footer_logo from "../../Assets/images/footer_logo.webp";
import food_bg_lines from "../../Assets/images/footer_bg_lines.webp";
import mob_icon from "../../Assets/images/mob-Icon.webp";
import mail_icon from "../../Assets/images/mail_icon.webp";
import fb_icon from "../../Assets/images/fb_icon.webp";
import insta_icon from "../../Assets/images/insta_icon.webp";
import contactUS from "../../Assets/images/ContactUS.webp";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import sideimg1 from "../../Assets/images/strip1.webp";
import sideimg2 from "../../Assets/images/strip2.webp";
import { NavRoutes } from "../Navbar/NavRoutes";
import { API_GET } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import HomeInquiryFormModal from "../../Pages/HomePage/Sections/HomeInquiryFormModal";

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("Fiesta"));
  const [footerData, setFooterData] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const getData = async () => {
    try
    {
      const res = await API_GET(API_endpoints.getFooterContent);
      res?.s && setFooterData(res?.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `radial-gradient(50% 50% at 50% 50%, #371F07 0%, #1A130C 100%)`,
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box sx={{ position: "absolute", inset: 0, order: 3 }}>
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={food_bg_lines}
          alt="background"
        />
      </Box>
      <Box sx={{ position: "absolute", left: 0, bottom: "50px" }}>
        <img
          src={sideimg1}
          alt=""
          height={40}
          style={{ transform: "scale(-1)" }}
        />
      </Box>
      <Box sx={{ position: "absolute", left: 0, top: "50px" }}>
        <img
          src={sideimg2}
          alt=""
          height={40}
          style={{ transform: "scale(-1)" }}
        />
      </Box>

      <Box
        sx={{ width: "90%", mx: "auto", py: 2, maxWidth: "1400px", oreder: 1 }}
      >
        {/* Image and footer logo */}
        <Box
          sx={{
            width: { xs: "160px", md: "200px" },
            height: { xs: "100px", md: "150px" },
            position: "absolute",
            right: 0,
            bottom: { xs: 30, md: 50 },
          }}
        >
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={food_truck}
            alt="caterer"
          />
        </Box>

        <Grid2
          container
          spacing={{ xs: 3, md: 2 }}
          sx={{
            justifyContent: "space-evenly",
            py: { xs: 2, md: 5 },
            // alignItems: "start",
            // border: "1px solid red"
          }}
        >
          {/* Footer Column 1 */}
          <Grid2 xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box
              sx={{
                width: { xs: "100%", md: "225px" },
                display: "flex",
                flexDirection: "column",
                gap: 1,
                px: { xs: 1, md: 0 },
                textAlign: { xs: "center", md: "start" },
                alignItems: { xs: "center", md: "start" },
              }}
            >
              <Box sx={{ width: "160px", height: "105px" }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={footer_logo}
                  alt="logo"
                />
              </Box>
              <Typography sx={{ color: theme.palette.text.greyText }}>
                {t("footer.content")}
              </Typography>
            </Box>
          </Grid2>

          {/* Footer Column 2 */}
          <Grid2 xs={12} sm={6} md={4} lg={4} xl={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: { xs: "center", sm: "start" },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.defaultLight,
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                {t("footer.quick")}
              </Typography>
              <Link style={{ textDecoration: "none" }} to={"/"}>
                <Typography
                  className="link"
                  sx={{ color: theme.palette.text.greyText, cursor: "pointer" }}
                >
                  {t("footer.home")}
                </Typography>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  display: userData?.role === 2 ? "none" : "block",
                }}
                to={NavRoutes.PARTNER}
              >
                <Typography
                  className="link"
                  sx={{ color: theme.palette.text.greyText, cursor: "pointer" }}
                >
                  {t("footer.register")}
                </Typography>{" "}
              </Link>
              <Link style={{ textDecoration: "none" }} to={NavRoutes.FAQ}>
                <Typography
                  className="link"
                  sx={{ color: theme.palette.text.greyText, cursor: "pointer" }}
                >
                  {t("footer.faq")}
                </Typography>
              </Link>
              <Link style={{ textDecoration: "none" }} to={NavRoutes.ABOUT}>
                <Typography
                  className="link"
                  sx={{ color: theme.palette.text.greyText, cursor: "pointer" }}
                >
                  {t("footer.about")}
                </Typography>
              </Link>
              <Link style={{ textDecoration: "none" }} onClick={handleOpen}>
                <Typography
                  className="link"
                  sx={{ color: theme.palette.text.greyText, cursor: "pointer" }}
                >
                  {t("footer.contact")}
                </Typography>
              </Link>
            </Box>
            <HomeInquiryFormModal open={open} setOpen={setOpen} />
          </Grid2>

          {/* Footer Column 3 */}
          <Grid2 xs={12} sm={6} md={4} lg={4} xl={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: { xs: "center", sm: "start" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: theme.palette.text.defaultLight,
                }}
              >
                {t("footer.touch")}
              </Typography>
              <Box sx={{ display: "flex", gap: 2, cursor: "pointer" }}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={mob_icon}
                  alt="phone"
                />
                <Typography
                  className="link"
                  sx={{ color: theme.palette.text.greyText }}
                >
                  <a
                    className="link"
                    style={{
                      textDecoration: "none",
                      color: theme.palette.text.greyText,
                    }}
                    href={`tel:+ ${footerData?.phno}`}
                  >
                    {footerData?.phno}
                  </a>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={mail_icon}
                  alt="email"
                />
                <Typography
                  sx={{
                    color: theme.palette.text.greyText,
                    cursor: "pointer",
                  }}
                >
                  <a
                    href={`mailto:${footerData?.email}`}
                    className="link"
                    style={{
                      textDecoration: "none",
                      color: theme.palette.text.greyText,
                    }}
                  >
                    {footerData?.email}
                  </a>
                </Typography>
              </Box>
            </Box>
          </Grid2>

        </Grid2>
      </Box>

      {/* Footer Bottom Section */}
      <Box
        sx={{
          width: "100%",
          bgcolor: theme.palette.background.strip,
          py: 2,
          gap: 1,
          order: 2,
        }}
      >
        <Box
          sx={{
            width: "90%",
            mx: "auto",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            gap: 1,
            maxWidth: "1000px",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Typography
              className="link"
              sx={{
                color: theme.palette.text.defaultLight,
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              onClick={() => navigate(NavRoutes.Imprint)}
            >
              {t("footer.Imprint")}
            </Typography>
            <Typography
              className="link"
              sx={{
                color: theme.palette.text.defaultLight,
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              onClick={() => navigate(NavRoutes.PRIVACY)}
            >
              {t("footer.privacy")}
            </Typography>
            <Typography
              className="link"
              onClick={() => navigate(NavRoutes.TERMS)}
              sx={{
                color: theme.palette.text.defaultLight,
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              {t("footer.tc")}
            </Typography>

          </Box>
          <Box sx={{ display: "flex", gap: 1, zIndex: 1 }}>
            <img
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
              src={fb_icon}
              className="imgHL"
              alt="facebook"
              onClick={() => window.open(`${footerData?.facebook}`)}
            />

            <img
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
              src={insta_icon}
              className="imgHL"
              alt="instagram"
              onClick={() => window.open(`${footerData?.insta}`)}
            />
            <img
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
              src={contactUS}
              className="imgHL"
              alt="x"
              onClick={() => navigate(NavRoutes.REACH)}
            />

          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
