export const NavRoutes = {
  HOME: "/",
  ABOUT: "/about",
  PARTNER: "/partner",
  TRUCKS: "/trucks",
  PROFILE: "/profile",
  CHAT: "/chat",
  LOGIN: "/login",
  REGISTER: "/register",
  TRUCK_DETAILS: "/truck-details",
  REACH: "/reach-us",
  FAQ: "/faq",
  OWNER_PROFILE: "/owner/profile",
  MY_FOOD_TRUCK: "/my-trucks",
  COMPANY_DETAILS:'/caterer',
  PRIVACY: "/policy",
  TERMS: "/terms",
  CATERING_LIST: "/cateringlist",
  ADD_TRUCK: "/addtruck",
  MY_COMPANY: "/mycompany",
  Imprint: "/imprint",
  Renew: '/renew',
  SearchScreen:"/search"
};
